import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Typography, List, Timeline, Table, Button, Modal } from "antd";
import { ContractDispersionModal } from "./modals/ContractDispersionModal";
import { contractDispersionActions } from "services/ContractDispersion/ContractDispersionSlice";

const Dispersions = () => {
  const dispatch = useDispatch()
  const { contract, status, payents } = useSelector((state) => state.contract);
  const { dispersionSelected } = useSelector((state) => state.contracDispersion);
  const { Title } = Typography;
  const formatDate = (end, start) => {
    return moment(end).diff(moment(start), "months");
  };

  const formatter = (input) => {
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    })
      .format(input)
      .replace("COP", "$")
      .replace(/,b/gi, ".");
  };

  const columnsTable = [
    {
      title: "Nombre",
      dataIndex: "bankAccountDistribution",
      render: (bankAccountDistribution) => bankAccountDistribution.name + ' ' + bankAccountDistribution.lastname

    },
    {
      title: "Tipo de cuenta",
      dataIndex: "bankAccountDistribution",
      render: (bankAccountDistribution) => {
        console.log(bankAccountDistribution);
        let data = '';
        switch (bankAccountDistribution.typeProdServ) {
          case 1:
          case 3:
            data = "Ahorros"
            break;
          case 2:
          case 4:
            data = "Corriente"
            break;
          case 3:
          case 6:
            data = "Convenio"
            break;
        }
        return data;
      }

    },
    {
      title: "Numero de cuenta",
      dataIndex: "bankAccountDistribution",
      render: (bankAccountDistribution) => bankAccountDistribution.numProdServ

    },
    {
      title: "Banco",
      dataIndex: "bankAccountDistribution",
      render: (bankAccountDistribution) => {
        let label = "";
        switch (bankAccountDistribution.bankId) {
          case 1: label = "Bank of America"; break;
          case 2: label = "Banco Agrario"; break;
          case 3: label = "Banco AV Villas"; break;
          case 4: label = "Banco Caja Social"; break;
          case 5: label = "Banco de Occidente"; break;
          case 6: label = "Banco Popular"; break;
          case 7: label = "Bancolombia"; break;
          case 8: label = "BBVA Colombia"; break;
          case 9: label = "Banco de Bogotá"; break;
          case 10: label = "Citi Colombia"; break;
          case 11: label = "Colpatria"; break;
          case 12: label = "Davivienda"; break;
          case 13: label = "GNB Sudameris"; break;
          case 14: label = "ITAU CORPBANCA COLOMBIA S.A"; break;
          case 15: label = "EDIFICIOS DAVIVIENDA"; break;
          case 16: label = "AVANZA"; break;
          case 17: label = "AV VILLAS CENTRO DE PAGOS VIRTUAL OPCION PERSONAS"; break;
          case 18: label = "COLPATRIA PAGOS PSE EN LINEA - CONJUNTOS RESIDENCIALES"; break;
          case 19: label = "BANCOOMEVA"; break;
          case 20: label = "EFECTIVO"; break;
          case 21: label = "OTRO"; break;
        }
        return label;

      }

    },
    {
      title: "Concepto",
      dataIndex: "concept",
      render: (concept) => {
        const label = (concept == 0) ? "Distribucion Personalizada" : "Administracion de inmueble";

        return label;
      }
    },
    {
      title: "Valor",
      dataIndex: "value",
      render: (value) => formatter(
        value
      )
    },
    {
      title: "Opciones",
      render: (Row) => {
        return <div><Button className='button' type="primary" onClick={() => handleUpdateDispersion(Row)}>
          Ver/Editar
        </Button><br></br><br></br><Button className='button' type="primary" value={Row.id} onClick={() => unActivateDispersion(Row.id)}>
            Eliminar
          </Button></div>
      }
    }
  ];

  const handleUpdateDispersion = (dispersionSelected) => {
    dispatch(contractDispersionActions.setState({ key: "dispersionSelected", value: dispersionSelected }));
    dispatch(contractDispersionActions.setState({ key: "contractDispersionModal", value: true }));
  };

  const unActivateDispersion = (id) => {
    Modal.confirm({
      title: "Desea eliminar la dispersion",
      onOk: () => {
        dispatch(contractDispersionActions.deleteDispersion({ id: parseInt(id) }))
      },
      onCancel: () => {
      }
    })

  };

  const { contractDispersionModal } = useSelector(
    (state) => state.contracDispersion
  );
  const handleAddDispersion = () => {
    dispatch(contractDispersionActions.setState({ key: "contractDispersionModal", value: true }));
  };


  return (
    <div>
      <div>
        <Button className='button' type="primary" onClick={handleAddDispersion} >
          Agregar Distribucion
        </Button>
        {contractDispersionModal && <ContractDispersionModal visible={contractDispersionModal} dispersionSelected={dispersionSelected} contract={contract} />}
      </div>
      <div>
      </div>
      <Table
        pagination={false}
        rowKey="discount_id"
        columns={columnsTable}
        dataSource={contract.contractDistributions}
        size="small"
        scroll={{ y: 410 }}
      />
    </div>
  );
};

export default Dispersions;
