import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Form, Select, Typography, Button } from "antd";

import { locationActions } from "../../../../../services/Location/LocationSlice";

const InfoSecond = ({ next, setSelectCity }) => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { Option } = Select;
  const { locations } = useSelector((state) => state.location);

  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  const onFinish = (values) => {
    return next(values);
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {
    const city = locations.filter((location) => location.id == e);
    setSelectCity(city);
  };

  return (
    <>
      <Title style={{ marginTop: 20, marginBottom: 20 }} level={5}>
        Información Básica
      </Title>
      <Form
        {...layoutstyled}
        form={form}
        name="register"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="issue_location"
          label="Ciudad, Departamento"
          rules={[{ required: true, message: "Por favor ingrese el nombre!" }]}
        >
          <Select
            showSearch
            placeholder={"Ciudad, Departamento"}
            showArrow={false}
            filterOption={false}
            onChange={getLocationOnChange}
            onSearch={getLocationOnSearch}
            notFoundContent={null}
          >
            {locations.map((location) => (
              <Option key={location.id} data={location}>
                {location.fullname}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="direction"
          label="Dirección "
          placeholder={"Cra # cll"}
          rules={[
            {
              required: true,
              message: "Ingresa una dirección",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={"Cra cll #"} />
        </Form.Item>

        <Form.Item
          name="prefix"
          label="Prefijo"
          placeholder={"prefix"}
          rules={[
            {
              required: true,
              message: "Ingresa un prefijo para el numero telefónico",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={"Prefijo"} />
        </Form.Item>
        <Form.Item
          name="contac"
          label="No. Contacto "
          placeholder={"Cel"}
          rules={[
            {
              required: true,
              message: "Ingresa un numero telefónico",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder={"Cel"} />
        </Form.Item>

        <Form.Item
          name="role_id"
          label="Rol "
          rules={[
            {
              required: true,
              message: "Seleccione un Rol",
            },
          ]}
        >
          <Select
            placeholder={"Roles"}
            filterOption={false}
            notFoundContent={null}
          >
            <Option key={3} value={3}>
              Editor
            </Option>
            <Option key={4} value={4}>
              Usuario
            </Option>
            <Option key={5} value={5}>
              Especialista
            </Option>
            <Option key={6} value={6}>
              Administrador de inmobiliaria
            </Option>
            <Option key={7} value={7}>
              Usuario de inmobiliaria
            </Option>
          </Select>
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default InfoSecond;
