import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: null,
  errormsg: null,
  loading: true,
  growArray: { active: [], closed: [] },
  companys: null,
  companyAd:null,
  companyConfAd:null,
  contractsIpc : [],
  filter: {},
  filterIpc: {},
  pagination: {
    total: 1,
    page: 1,
    limit: 10,
  },
  isViewRenewUpdate : false,
  renewUpdateSel : false
};

const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    getCompanys() { },
    setCompanys(state, { payload }) {
      state.companys = payload;
    },
    setCompanyAd(state, { payload }) {
      state.companyAd = payload;
    },
    setCompanyConfAd(state, { payload }) {
      state.companyConfAd = payload;
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    getGrowCompany() { },
    setGrowArray(state, { payload }) {
      state.status = false;
      state.growArray = payload.items;
      state.filter = payload.filter;
    },
    getCompany() { },
    getIpcContractList(){},
    setIpcArray(state, { payload }) {
      state.status = false;
      state.contractsIpc = payload.items;
      state.filterIpc = payload.filter;
      state.pagination = payload.pagination;
    },
    setIsViewRenewUpdate(state, { payload }) {
      state.isViewRenewUpdate = payload;
    },
    setRenewUpdateSel(state, { payload }) {
      state.renewUpdateSel = payload;
    },
    getRenewContractList(){},
    applyRegisterToContract(){},
    setUpdateRenewRegister(){},
    sendRenewLetter(){},
    setUpdateCompanyConfigBill(){},
    updateCompanyDueBillConf(){},
    activateCompanyDueBillConf(){}
  },
});

const companyActions = companySlice.actions;
const companyReducer = companySlice.reducer;

export { companyActions, companyReducer };
