import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions,Collapse , Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col, Select, Form } from "antd";
import { useHistory } from "react-router-dom";
import ApiService from "../../../../common/api/Api"
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { propertyActions } from "services/Property/PropertySlice";
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { columnsDispersionData, columnsDispersionHistory } from "../constans";
import TableRow from "scenes/Private/StateAccount/pdf_gen/TableRow";
import { formmatterCurrency } from "common/utilities";
import moment from "moment";
import "moment/locale/es";
import styles from "./css/detail_dispersion_modal.module.css";
const { Panel } = Collapse;

export const ModalDispersionData = (cart) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { cartsDispersion } = useSelector(state => state.dispersion);
  const { dispersionData } = useSelector(state => state.shopping);
  const { property } = useSelector(state => state.property);
  const [shopCart, setShopCart] = useState([]);
  const [shopCartSel, setShopCartSel] = useState(0);
  const [propId, setPropId] = useState("");
  const [propName, setPropName] = useState("");
  const [costCon, setConCost] = useState(0);
  const [costConDis, setConCostDis] = useState(0);
  const [payDate, setPayDate] = useState("");
  const [cartAct, setCartAct] = useState("");
  const currentColumnHistory = columnsDispersionHistory();
  const currentcolumnsDisData = columnsDispersionData();
  const { payload, otraPropiedad } = dispersionData || {};
  const listaDeDispersion = payload?.dispersion || [];
  const historial = payload?.history || [];

  useEffect(() => {
    if (cartAct != cart.id) {
      setShopCart([]);
      dispatch(dispersionActions.setCartsDispersion([]))
      setCartAct(cart.id);
      if (!dispersionData || property != null) {

        let cart_search = {
          "id": cart.cart.shoppingCartId ? cart.cart.shoppingCartId : 0,
          "code": cart.cart.consecutiveDisGen
        }
        if (dispersionData != cart_search) {
          dispatch(shoppingActions.getShoppingDataDis({ cart: cart_search }))
        }

        //se obtiene la info de la propiedad   
        dispatch(propertyActions.getProperty(cart.cart.metadata[0].property))
      } else {
        dispatch(propertyActions.setProperty(null));

        dispatch(propertyActions.getProperty(cart.cart.metadata[0].property))

      }
      if (cart.cart.shoppingCartId == null) {
        dispatch(dispersionActions.getCartsWithoutDis({ contract_id: cart.cart.contractId }))
      }
    }

  }, [dispersionData, dispatch, cart])

  useEffect(() => {
    if (property != null) {
      setPropId(property?.id);
      setPropName(property?.title)
      let costConTotal = 0;
      let costConTotalDis = 0;
      let cost_con = property.contract_active[0];
      cost_con.costs.forEach(async cost => {
        if (cost_con.dis_exclude_admin == 1 && cost.cost_type_id == 2) {
          costConTotalDis += 0;
        } else {
          costConTotalDis += cost.value;
        }
        costConTotal += cost.value;
      })
      setConCost(costConTotal)
      setConCostDis(costConTotalDis)
    } else {
      setPropId("");
      setPropName("")
      setConCost("")
    }

  }, [property])

  useEffect(() => {
    if (cartsDispersion && cartsDispersion.items) {
      if (cartsDispersion.items.length > 0) {
        let data_service = cartsDispersion.items;
        const data_select = data_service.map(item => ({
          value: item.id,
          label: item.id.toString(), // Mostrar el ID en el Select
        }));
        setShopCart(data_select);
      }
    }
  }, [cartsDispersion])


  const showDataError = () => {
    const errores = historial.map((dato, index) => {
      let metadata = dato.metadata;
      if (metadata.length == 0) {
        return (
          <Col key={index} className={styles.casillas}>
            <p>* {dato.concept}</p>
          </Col>
        );
      } else {
        return null;
      }
    });

    return errores.filter(error => error !== null);
  }
  let showDataGeneratedFees = () => {
    if (dispersionData) {
      let dis_con = dispersionData.payload?.dispersion[0]?.dispersionContext;
      let admin_value = formmatterCurrency(0);
      let val_disount = 0;
      historial.map((dato, index) => {
        if (dato.concept == "Administracion") {
          admin_value = formmatterCurrency(dato.value);
        }
        /*else {
          let metadata = dato.metadata;
          if (metadata.length >= 1) {
            if (metadata[0].is_discount == true) {
              val_disount += dato.value;
            }
          } 
        }*/
      });
      let type = dis_con.type;
      let value = (type == 1) ? formmatterCurrency(dis_con.commission) : (100 * dis_con.commission).toFixed(2) + "%";

      let type_com = dis_con.type;
      let value_com = (type_com == 1) ? formmatterCurrency(dis_con.commissionInmo) : (100 * dis_con.commissionInmo).toFixed(2) + "%";

      let type_sec = dis_con.type;
      let value_sec = (type_sec == 1) ? formmatterCurrency(dis_con.commissionSecure) : (100 * dis_con.commissionSecure).toFixed(2) + "%";

      let discount = formmatterCurrency(val_disount);
      let head = (
        <div>
          <Row className={`${styles.contenedor_first} ${styles.headerRow}`}>
            <div className={`${styles.cell} ${styles.firstColumn}`}>
              <label></label>
            </div>
            <div className={styles.cell}>
              <label>Recaudo</label>
            </div>
            <div className={styles.cell}>
              <label>Comision Yampi</label>
            </div>
            <div className={styles.cell}>
              <label>Comision Inmobiliaria</label>
            </div>
            <div className={styles.cell}>
              <label>Comision aseguradora</label>
            </div>
            <div className={styles.cell}>
              <label>Administracion</label>
            </div>
            <div className={styles.cell}>
              <label>Descuentos/Adiciones</label>
            </div>
            <div className={styles.cell}>
              <label>Saldo a transferir</label>
            </div>
          </Row>
          <Row className={styles.contenedor}>
            <div className={`${styles.cell} ${styles.firstColumn}`}>
              <label></label>
            </div>
            <div className={styles.cell}>
              <label></label>
            </div>
            <div className={styles.cell}>
              <label>{(value)}</label>
            </div>
            <div className={styles.cell}>
              <label>{(value_com)}</label>
            </div>
            <div className={styles.cell}>
              <label>{(value_sec)}</label>
            </div>
            <div className={styles.cell}>
              <label>{admin_value}</label>
            </div>
            <div className={styles.cell}>
              <label>{/*discount*/}</label>
            </div>
            <div className={styles.cell}>
              <label></label>
            </div>
          </Row>
        </div>
      )
      return head;
    }
  }
  const showDataGeneratedProp = () => {
    if (dispersionData) {
      //se obtiene el registro por el tipo de dispersion
      let dis_con_regs = dispersionData.payload?.dispersion;
      let dis_con = dispersionData.payload?.dispersion[0];
      let created = moment(dis_con.createdAt).format("YYYY-MM-DD")
      let com_inmo = 0;
      if (created >= "2025-01-17") {
        dis_con_regs.map((dato_reg, index) => {
          if (dato_reg.type == 0) {
            dis_con = dato_reg;
          }
          if (dato_reg.type == 1) {
            com_inmo = dato_reg.valueTotal;
          }
        });
      } else {
        com_inmo = dis_con.valueInmo;
      }


      let admin_value = formmatterCurrency(0);
      let val_disount = 0;
      historial.map((dato, index) => {
        if (dato.concept == "Administracion") {
          admin_value = formmatterCurrency(-dato.value);
        } else {
          let metadata = dato.metadata;
          if (metadata.length >= 1) {
            if (metadata[0].is_discount == true && (metadata[0].entity_applied == 3 || metadata[0].entity_applied === undefined || metadata[0].entity_applied == 6)) {
              val_disount += dato.value;
            }
          }

        }
      });
      let discount = formmatterCurrency(val_disount);

      let head = (
        <div>
          <Row className={styles.contenedor}>
            <div className={styles.cell}>
              <label>Propietario</label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(dis_con.valueTotal)}</label>
            </div>
            <div className={styles.cell}>
              <label> $ 0.0 </label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(-com_inmo)}</label>
            </div>
            <div className={styles.cell}>
              <label> $ 0.0 </label>
            </div>
            <div className={styles.cell}>
              <label>{admin_value}</label>
            </div>
            <div className={styles.cell}>
              <label>{discount}</label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(dis_con.valuePay)}</label>
            </div>
          </Row>

        </div>
      )
      return head;
    }
  }
  const showDataGeneratedInmo = () => {
    if (dispersionData) {
      let inmo_transfer = formmatterCurrency(0);
      historial.map((dato, index) => {
        if (dato.concept == "Valor a recibir por parte de yampi la inmobiliaria") {
          inmo_transfer = formmatterCurrency(dato.value);
        }
      });

      let val_disount = 0;
      historial.map((dato, index) => {
        let metadata = dato.metadata;
        if (metadata.length >= 1) {
          //if (metadata[0].entity_applied == 5 || metadata[0].entity_applied == 2 || metadata[0].entity_applied == 1) {
          if (metadata[0].entity_applied == 2) {
           // if (!metadata[0].last_adjust) {
              val_disount += dato.value;
            //}
          }
        }
      });
      //se obtiene el registro por el tipo de dispersion
      let dis_con_regs = dispersionData.payload?.dispersion;
      let dis_con = dispersionData.payload?.dispersion[0];
      let created = moment(dis_con.createdAt).format("YYYY-MM-DD")
      let com_inmo = 0;
      let total_init = 0;
      if (created >= "2025-01-17") {
        dis_con_regs.map((dato_reg, index) => {
          if (dato_reg.type == 1) {
            dis_con = dato_reg;
            total_init = dis_con.valueTotal;
          }
          if (dato_reg.type == 4) {
            com_inmo = dato_reg.valuePay;
          }
        });
      } else {
        com_inmo = dis_con.valueCom;
        total_init = dis_con.valueInmo;
      }


      let head = (
        <div>
          <Row className={styles.contenedor}>
            <div className={styles.cell}>
              <label>Inmobiliaria</label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(total_init)}</label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(-com_inmo)} </label>
            </div>
            <div className={styles.cell}>
              <label> $ 0.0 </label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(-dis_con.valueSafeSecure)}</label>
            </div>
            <div className={styles.cell}>
              <label> $ 0.0 </label>
            </div>
            <div className={styles.cell}>
              <label>{formmatterCurrency(val_disount)}</label>
            </div>
            <div className={styles.cell}>
              <label>{inmo_transfer} </label>
            </div>
          </Row>

        </div>
      )
      return head;
    }
  }
  const showDataGeneratedAdmin = () => {
    let admin_value = formmatterCurrency(0);
    let admin_dis_value = formmatterCurrency(0);
    let val_disount = 0;
    historial.map((dato, index) => {
      if (dato.concept == "Administracion") {
        admin_value = formmatterCurrency(dato.value);
      }
      if (dato.concept == "Valor a recibir la administracion") {
        admin_dis_value = formmatterCurrency(dato.value);
      }
      let metadata = dato.metadata;
      if (metadata.length >= 1) {
        if (metadata[0].entity_applied == 4) {
          val_disount += dato.value;
        }
      }
    });



    let head = (
      <div>
        <Row className={styles.contenedor}>
          <div className={styles.cell}>
            <label>Administracion</label>
          </div>
          <div className={styles.cell}>
            <label>{admin_value}</label>
          </div>
          <div className={styles.cell}>
            <label> $ 0.0 </label>
          </div>
          <div className={styles.cell}>
            <label>$ 0.0</label>
          </div>
          <div className={styles.cell}>
            <label>$ 0.0</label>
          </div>
          <div className={styles.cell}>
            <label> $ 0.0 </label>
          </div>
          <div className={styles.cell}>
            <label> {formmatterCurrency(val_disount)}</label>
          </div>
          <div className={styles.cell}>
            <label>{admin_dis_value}</label>
          </div>
        </Row>

      </div>
    )
    return head;

  }
  const { Panel } = Collapse;
  const showDiscountsAndAditions = () => {
    if (!dispersionData) return null;
  
    let discounts = {
      yampi: { value: 0, labels: [] },
      general: { value: 0, labels: [] },
      custom: { value: 0, labels: [] },
      propietario: { value: 0, labels: [] },
      inmobiliaria: { value: 0, labels: [] },
      administracion: { value: 0, labels: [] },
      aseguradora: { value: 0, labels: [] },
    };
  
    historial.forEach((dato) => {
      if (dato.concept === "Administracion") return;
      let metadata = dato.metadata?.[0];
      if (!metadata) return;
  
      if (metadata.is_discount) {
        if (metadata.is_discount_custom) {
          discounts.custom.value += dato.value;
          discounts.custom.labels.push(`${dato.concept}: ${dato.value}`);
        } else {
          discounts.general.value += dato.value;
        }
  
        switch (metadata.entity_applied) {
          case 3:
          case 6:
          case undefined:
            discounts.propietario.value += dato.value;
            discounts.propietario.labels.push(`${dato.concept}: ${dato.value}`);
            break;
          case 2:
            discounts.inmobiliaria.value += dato.value;
            discounts.inmobiliaria.labels.push(`${dato.concept}: ${dato.value}`);
            break;
          case 1:
            if (metadata.last_adjust) {
              discounts.yampi.value += dato.value;
              discounts.yampi.labels.push(`${dato.concept}: ${dato.value}`);
            }
            break;
          case 4:
            if (metadata.last_adjust) {
              discounts.administracion.value += dato.value;
              discounts.administracion.labels.push(`${dato.concept}: ${dato.value}`);
            }
            break;
          case 5:
            if (metadata.last_adjust) {
              discounts.aseguradora.value += dato.value;
              discounts.aseguradora.labels.push(`${dato.concept}: ${dato.value}`);
            }
            break;
        }
      }
    });
  
    return (
      <div className="p-4 border rounded-lg">
        <h3 className="text-xl font-semibold mb-4">Descripción de descuentos y adiciones</h3>
        {Object.values(discounts).some(d => d.labels.length > 0) ? (
          <Collapse accordion>
            {Object.entries(discounts).map(([key, { value, labels }], index) =>
              labels.length > 0 ? (
                <Panel header={`Aplicados a ${key} (${formmatterCurrency(value)})`} key={index}>
                  {labels.map((label, i) => (
                    <p key={i} className="text-sm">{label}</p>
                  ))}
                </Panel>
              ) : null
            )}
          </Collapse>
        ) : (
          <p>No hay descuentos ni adiciones.</p>
        )}
      </div>
    );
  };

  const handleSubmit = (e) => {
    //console.log("Formulario enviado");
    //console.log("Carrito seleccionado:", shopCartSel);
    if (shopCartSel != 0) {
      let assign_data = {
        "shopping_cart": parseInt(shopCartSel),
        "contract_id": cart.cart.contractId,
        "code_gen": parseInt(cart.cart.consecutiveDisGen)
      }
      //console.log(assign_data);
      dispatch(dispersionActions.assignateCartDis({ assign_data }))
      setShopCart([]);
      dispatch(dispersionActions.setCartsDispersion([]))
    }
  };

  const handleChange = (value, option) => {
    setShopCartSel(value);
  };

  let assignateCart = () => {
    let select_cart = "";
    if (cart.cart.shoppingCartId == null) {
      select_cart = <Form onFinish={handleSubmit} >
        <Form.Item>
          <Select
            showSearch
            value={shopCartSel}
            placeholder={"Carrito disponible"}
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={handleChange}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={shopCart}
          >
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Asignar
          </Button>
        </Form.Item>
      </Form>

    }
    let head = (
      <div>
        <div >
          <label>{cart.cart.shoppingCartId}</label> <br />
          {select_cart}
        </div>
      </div>
    )
    return head;
  }

  return (
    <>
      <Row>
        <Col>
          <Row className={styles.contenedor}>
            <div className={styles.cell_medium}>
              <label>Propiedad</label>
            </div>
            <div className={styles.cell_title}>
              <span onClick={() => history.push(`/property/${propId}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>
                {propName}
              </span>
            </div>
          </Row>
          <Row className={styles.contenedor}>
            <div className={styles.cell_medium}>
              <label>Carrito de pago</label>
            </div>
            <div className={styles.cell_title}>
              {assignateCart()}
            </div>
          </Row>
          <Row className={styles.contenedor}>
            <div className={styles.cell_medium}>
              <label>Valor contrato</label>
            </div>
            <div className={styles.cell_title}>
              {formmatterCurrency(costCon)}
            </div>
          </Row>
          <Row className={styles.contenedor}>
            <div className={styles.cell_medium}>
              <label>Valor base de dispersion</label>
            </div>
            <div className={styles.cell_title}>
              {formmatterCurrency(costConDis)}
            </div>
          </Row>
          <br /> <br />
          <Col>
            {showDataError().length > 0 && (
              <>
                <label>⚠️ Impedimentos para realizar la dispersión:</label>
                <div>{showDataError()}</div>
              </>
            )}
          </Col> <br />
          <Col>
          </Col>
          <Col>
            {showDataGeneratedFees()}
          </Col>
          <Col>
            {showDataGeneratedProp()}
          </Col>
          <Col>
            {showDataGeneratedInmo()}
          </Col>
          <Col>
            {showDataGeneratedAdmin()}
          </Col>
          <label></label> <br />
          <Col>
            {showDiscountsAndAditions()}
          </Col>
        </Col>
      </Row>
    </>
  );
};
