import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { shoppingActions } from './ShoppingSlice'
import { getStatus } from '../../common/constants/status'
import { apiUrlV2 } from 'common/config/Environments'

function* getShoppingsProperty({ payload }) {

  yield put(shoppingActions.onChangeStatus('loading'))
  try {
    const payments = yield ApiService.post(`/shopping-prop/${payload.role}/${payload.propertyId}`, { type: 0, date_search: new Date() })
    yield put(shoppingActions.setShoppingsProperty(payments))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    yield put(shoppingActions.onError(err.toString()))
  }
}

function* zonaPagosInitPayment({ payload }) {
  try {
    yield put(shoppingActions.onChangeStatus('loading'))
    const response = yield ApiService.requestPost(`/zona_pagos/init-payment`, payload)
    yield put(shoppingActions.setZonaPagosInitPayment(response))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    yield put(shoppingActions.onError('No se pudo continuar con el pago'))
  }
}

function* editPay({ payload }) {
  try {
    yield put(shoppingActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`/admin/shopping-update`, payload.valuePay)
    yield put(shoppingActions.getShoppingsProperty({ role: 0, propertyId: payload.propertyId }))
    yield put(shoppingActions.onChangeStatus('success'))
    yield put(shoppingActions.setEditPay({ response: "Cambio realizado con exito." }))

  } catch (err) {
    yield put(shoppingActions.setEditPay({ response: "Error al editar el valor, Comunicate con el servicio técnico de Yampi para mas información." }))
    yield put(shoppingActions.onError('No se pudo continuar con el pago'))
  }
}

function* insertDispersion({ payload }) {
  try {
    yield put(shoppingActions.onChangeStatus('loading'))
    const data = JSON.parse(payload.metadata)
    const response = yield ApiService.post(`/admin/insert-dispersion`, { user_id: payload.user_id, contract_id: data['App\\Models\\Contract'][0], shopping_cart_id: payload.id, entity: "contract", "payment_method": 4, "pay_observation": payload.observation })
    yield put(shoppingActions.setInsertDispersion(response))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err)
    yield put(shoppingActions.onError('No se pudo continuar con el pago'))
  }
}

function* generatePayment({ payload }) {
  console.log("🚀 ~ function*generatePayment ~ payload:", payload)
  try {
    yield put(shoppingActions.onChangeStatus('loading'))
    const response = yield ApiService.requestPost(`/payment`, {
      "contract_id": payload.contract_id,
      "is_visible": true,
      "secuence_pay": payload.secuence_pay,
      "secuence_pay_end": payload.secuence_pay_end,
      "canon_period_number": payload.payment_period,
      "dispersion_apply": (payload.dispersion_apply) ? payload.dispersion_apply : 4,
      "date_for_pay": "test"
    })
    yield put(shoppingActions.setInsertDispersion({ ok: 'ok' }))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err)
    /* yield put(shoppingActions.onError('No se pudo continuar con el pago')) */
  }
}

function* discount({ payload }) {
  try {
    yield put(shoppingActions.onChangeStatus('loading'))
    //const response = yield ApiService.post(`/admin/payment-discount`, payload)
    const response = yield ApiService.requestPost(`/dispersion-discount/payment-discount`, payload)
    console.log(response)
    yield put(shoppingActions.setDiscount(response))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err)
    yield put(shoppingActions.onError('No se pudo continuar con el pago'))
  }
}

function* getShoppings({ payload: { page, limit, date_filter, created_at } }) {
  yield put(shoppingActions.onChangeStatus('loading'))
  try {
    let body = date_filter;
    const response = yield ApiService.requestPost(`/payment/list_payments?page=${page}&limit=${limit}`, body);
    let { items, meta } = response.payload;
    let data = items.map((shopping, index) => ({
      ...shopping,
      key: index,
      status: getStatus(shopping?.statusId).label,
      service_name: "test",
      user_name: `${shopping.user?.profiles[0]?.name || ''} ${shopping?.user?.profiles[0]?.lastname || ''}`
    }))
    const pagination = { page, limit, total: meta.totalItems }
    yield put(shoppingActions.setShoppings({ data, pagination }))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err);
    yield put(shoppingActions.onError(err.toString()))
  }
}

function* getShopping({ payload }) {
  yield put(shoppingActions.onChangeStatus('loading'))
  try {
    const body = { type: 0 }
    const response = yield ApiService.get(`/admin/shopping/${payload}`)
    let { data, total } = response
    const responsePayu = yield ApiService.post(`/admin/shopping-payments-payu`, { shopping_cart_id: response.id })
    const responseZonaPago = yield ApiService.requestPost(`/zona_pagos/verify-payment-single`, { "shopping_card_id": response.id })
    data = {
      ...response,
      status: getStatus(response?.status?.id).label,
      service_name: response?.service[0]?.name,
      user_name: `${response.user?.profile?.name || ''} ${response?.user?.profile?.lastname || ''}`,
      service: response?.service?.map((service, index) => ({ ...service, key: index })),
      responsePayu,
      responseZonaPago
    }
    console.log(responseZonaPago)
    yield put(shoppingActions.setShopping(data))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err)
    yield put(shoppingActions.onError(err.toString()))
  }
}

function* rePayment({ payload }) {
  yield put(shoppingActions.onChangeStatus('loading'))
  try {
    const body = { type: 0 }
    const response = yield ApiService.get(`/re-payment/${payload.shopping_id}`)
    yield put(shoppingActions.setInsertDispersion(response))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    yield put(shoppingActions.onError(err.toString()))
  }
}
function* deleteShopping({ payload }) {
  yield put(shoppingActions.onChangeStatus('loading'))
  try {
    const response = yield ApiService.delete(`/admin/payment-cart/${payload.shopping_id}`)
    yield put(shoppingActions.getShoppingsProperty({ role: 0, propertyId: payload.propertyId }))
    yield put(shoppingActions.onChangeStatus('success'))
    payload?.callback && payload.callback('OPEN_SNACKBAR', 'success', 'OK', 'Se Borro con exito')
  } catch (err) {
    payload?.callback && payload.callback('OPEN_SNACKBAR', 'error', 'Error', 'Ocurrio un error al intentar elminar el pago')
    yield put(shoppingActions.getShoppingsProperty({ role: 0, propertyId: payload.propertyId }))
    yield put(shoppingActions.onError(err.toString()))
  }
}


function* dispersion({ payload }) {
  try {
    yield put(shoppingActions.onChangeStatus('loading'))
    console.log(payload)
    /*  
      const response = yield ApiService.post(`/admin/shopping-update`, { shopping_cart_id: payload.id })
      console.log(response) */
    payload?.callback && payload.callback()
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err)
    yield put(shoppingActions.onError('No se pudo continuar con el pago'))
  }
}


function* getShoppingDataDis({ payload }) {
  try {
    const response = yield yield ApiService.request(
      `/payment/payment_data_dis/${payload.cart.id}/${payload.cart.code}`,
      "GET"
    );
    yield put(shoppingActions.setShoppingDataDis(response))
    yield put(shoppingActions.onChangeStatus('success'))
  } catch (err) {
    console.log(err)
    /* yield put(shoppingActions.onError('No se pudo continuar con el pago')) */
  }
}

function* ActionWatcher() {
  yield takeLatest(shoppingActions.getShoppings, getShoppings)
  yield takeLatest(shoppingActions.getShopping, getShopping)
  yield takeLatest(shoppingActions.getShoppingsProperty, getShoppingsProperty)
  yield takeLatest(shoppingActions.zonaPagosInitPayment, zonaPagosInitPayment)
  yield takeLatest(shoppingActions.insertDispersion, insertDispersion)
  yield takeLatest(shoppingActions.discount, discount)
  yield takeLatest(shoppingActions.generatePayment, generatePayment)
  yield takeLatest(shoppingActions.rePayment, rePayment)
  yield takeLatest(shoppingActions.editPay, editPay)
  yield takeLatest(shoppingActions.dispersion, dispersion)
  yield takeLatest(shoppingActions.deleteShopping, deleteShopping)
  yield takeLatest(shoppingActions.getShoppingDataDis, getShoppingDataDis)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
