import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { discountsActions } from "services/Discounts/DiscountsSlice";
// Constantes con las opciones
const SINGLE_OPTIONS = [{ value: 0, label: "Siempre" }];
const MONTH_OPTIONS = [
  { value: 1, label: "Enero" },
  { value: 2, label: "Febrero" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  { value: 12, label: "Diciembre" },
];

const ENTITY_APPLIED = [
  { value: 7, label: "Inquilino" }
];

const ENTITY_APPLIED_END = [
  { value: 0, label: "No aplica" },
  { value: 1, label: "Yampi" },
  { value: 2, label: "Inmobiliaria" },
  { value: 3, label: "Propietario" },
  { value: 4, label: "Administración" },
  { value: 5, label: "Aseguradora" },
];

const TYPE_APPLIED = [
  { value: 1, label: "Porcentual" },
  { value: 0, label: "Nominal" },
];

const TYPE_FEE_APPLIED = [
  { value: 0, label: "no aplica" },
  { value: 1, label: "Yampi" },
  { value: 2, label: "Inmobiliaria" },
  { value: 3, label: "Aseguradora" },
];

export const DiscountModal = ({ visible, discount, contractId,dispersion_con}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isFeeAppliedTypeZero, setIsFeeAppliedTypeZero] = useState(false);

  // Función para actualizar las opciones del periodo según "recurrent"
  const changePeriodAppli = (isUnique) => {
    if (isUnique) {
      setFilteredOptions(SINGLE_OPTIONS);
      form.setFieldsValue({ period_applied: 0 });
    } else {
      setFilteredOptions(MONTH_OPTIONS);
      form.setFieldsValue({ period_applied: null });
    }
  };

  // Manejo centralizado de cambios en los campos del formulario
  const handleValuesChange = (changedValues) => {
    if (changedValues.fee_applied_type !== undefined) {
      if (changedValues.fee_applied_type === 0) {
        form.setFieldsValue({
          type_value_fee: 0,
          value_fee_applied: 0,
        });
        setIsFeeAppliedTypeZero(true);
      } else {
        //se valida el fee
        let value_fee_con = 0;
        switch (changedValues.fee_applied_type) {
          case 1:
            value_fee_con = dispersion_con.commission;
            break;
          case 2:
            value_fee_con = dispersion_con.commissionInmo;
            break;
          case 3:
            value_fee_con = dispersion_con.commissionSecure;
            break;
          case 4:
            value_fee_con = dispersion_con.commission + dispersion_con.commissionSecure;
            break;
        } 
        form.setFieldsValue({
          type_value_fee: 1,
          value_fee_applied: value_fee_con,
        });
        //setIsFeeAppliedTypeZero(false);
      }
    }
    // Si se cambia "recurrent", actualizamos el listado de opciones para "period_applied"
    if (changedValues.recurrent !== undefined) {
      changePeriodAppli(changedValues.recurrent);
    }
  };

  // Si existe "discount", inicializamos el formulario con sus valores
  useEffect(() => {
    if (discount) {
      // Se determina si el periodo es "única vez" basado en periodApplied (0: única vez)
      const isUnique = discount.period_applied === 0;
      changePeriodAppli(isUnique); 
      form.setFieldsValue({
        ...discount,
        recurrent: discount.recurrent === 1,
        type: discount.type === 1,
        is_active: discount.is_active === 1,
        period_applied: discount.period_applied,
        value_fee_applied: (discount.dispersion_discount?.valueFeeApplied === 0 || discount.dispersion_discount === null ) ? 0 : discount.dispersion_discount?.valueFeeApplied, 
        entity_applied: 7,//discount.dispersion_discount?.entityApplied,
        entity_applied_end: (discount.dispersion_discount?.entityAppliedEnd === 0 || discount.dispersion_discount === null ) ? 0 : discount.dispersion_discount?.entityAppliedEnd,
        fee_applied_type:   (discount.dispersion_discount?.feeAppliedType === 0 || discount.dispersion_discount === null ) ? 0 : discount.dispersion_discount?.feeAppliedType,
        type_value_fee:     (discount.dispersion_discount?.typeValueFee === 0 || discount.dispersion_discount === null ) ? 0 : discount.dispersion_discount?.typeValueFee, 
      });
      handleValuesChange({ fee_applied_type: discount.dispersion_discount?.feeAppliedType });
    }
  }, [discount, form]);

  const hideModal = () => {
    dispatch(discountsActions.setState({ key: "discountModal", value: false }));
    dispatch(discountsActions.setState({ key: "discountSelected", value: null }));
  };

  const handleFinish = (values) => { 
    values.contract_id = parseInt(contractId, 10);
    if (discount) { 
      values.entity_applied = 3;
      let data_dic_act = discount.dispersion_discount_id;
      dispatch(discountsActions.updateDiscountContract({ con_adj_pay_id: parseInt(discount.id),have_dis_old : data_dic_act, contract_id:values.contract_id , ...values}));
      //dispatch(discountsActions.updateDiscountContract({ con_adj_pay_id: parseInt(discount.id),contract_id:values.contract_id , ...payload_2 }));
      //const id_dis = parseInt(discount.dispersion_discount_id, 10);
      //dispatch(discountsActions.updateDiscountV2({ id: id_dis, ...values }));
    } else { 
      dispatch(discountsActions.createDiscountContract(values));
    }  
  };

  return (
    <Modal
      title="Detalle de novedad de inquilino"
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      width="50%"
      className="ticket-modal"
    >
      <Form
        layout="vertical"
        name="createDiscount"
        form={form}
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="concept"
              label="Descripción de la novedad"
              rules={[{ required: true, message: "Ingrese la descripción" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="value"
              label="Valor"
              rules={[{ required: true, message: "Ingrese el valor de la novedad" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recurrent"
              label="Frecuencia"
              rules={[{ required: true, message: "Seleccione la frecuencia" }]}
            >
              <Select
                size="large"
                options={[
                  { value: true, label: "Periódico" },
                  { value: false, label: "Única vez" },
                ]}
                onChange={changePeriodAppli}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Tipo"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                options={[
                  { value: false, label: "Descuento" },
                  { value: true, label: "Adición" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_active"
              label="Estado"
              rules={[{ required: true, message: "Seleccione el estado" }]}
            >
              <Select
                size="large"
                options={[
                  { value: false, label: "Inactivo" },
                  { value: true, label: "Activo" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="period_applied"
              label="Periodo de aplicación"
              rules={[{ required: true, message: "Seleccione el mes" }]}
            >
              <Select
                size="large"
                options={filteredOptions}
                placeholder="Selecciona un mes"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="entity_applied"
              label="A quién se aplica la novedad"
              rules={[{ required: true, message: "Seleccione una opción" }]}
            >
              <Select
                size="large"
                options={ENTITY_APPLIED}
                placeholder="Selecciona una opción"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="entity_applied_end"
              label="A quién se destina la novedad"
              rules={[{ required: true, message: "Seleccione una opción" }]}
            >
              <Select
                size="large"
                options={ENTITY_APPLIED_END}
                placeholder="Selecciona una opción"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fee_applied_type"
              label="Tipo de tarifa aplicado"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                options={TYPE_FEE_APPLIED}
                placeholder="Selecciona un tipo"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type_value_fee"
              label="Tipo de valor de la tarifa"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                options={TYPE_APPLIED}
                placeholder="Selecciona el tipo"
                disabled={isFeeAppliedTypeZero}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="value_fee_applied"
              label="Valor de la tarifa"
              rules={[
                { required: true, message: "Ingrese el valor de la novedad" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const mainValue = getFieldValue("value");
                    if (
                      value !== undefined &&
                      mainValue !== undefined &&
                      value > mainValue
                    ) {
                      return Promise.reject(
                        new Error(
                          "El valor aplicado no puede ser mayor que el valor principal"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="large"
                disabled={isFeeAppliedTypeZero}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {discount ? "Editar" : "Agregar"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
