import { useEffect, useState } from 'react';
import { Card, Form, Button, Input, Col, Modal } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { companyActions } from 'services/Company/CompanySlice';


export const IncrementCart = () => {
  const dispatch = useDispatch()
  const { companyAd } = useSelector((state) => state.company);
  const [form] = Form.useForm();
  const [labelConf, setLabelConf] = useState("Usar esta configuración");
  // Cargar valores al formulario cuando companyAd cambia
  useEffect(() => {
    const defaultData = {
      date1: { diff_dia: 0, value: 0 },
      date2: { diff_dia: 0, value: 0 },
      date3: { diff_dia: 0, value: 0 },
    }
    if (companyAd?.applyDueCart == 1) {
      setLabelConf("No aplicar conficguracion de mora")
    } else {
      setLabelConf("Aplicar configuracion de mora")
    }
    if (companyAd?.dueCartConfig) {
      try {
        // Verificar si dueCartConfig es un string JSON y convertirlo
        const parsedData = typeof companyAd.dueCartConfig === "string"
          ? JSON.parse(companyAd.dueCartConfig)
          : companyAd.dueCartConfig;

        // Fusionar con valores por defecto en caso de datos faltantes
        const mergedData = { ...defaultData, ...parsedData };

        form.setFieldsValue(mergedData);
      } catch (error) {
        console.error("Error al cargar los datos en el formulario:", error);
        form.setFieldsValue(defaultData); // En caso de error, establecer valores por defecto
      }
    } else {
      // Si no hay datos en dueCartConfig, establecer los valores por defecto
      form.setFieldsValue(defaultData);
    }
  }, [companyAd, form]);

  const handleFinish = (values) => {
    //se guarda la info
    console.log("Formulario enviado:", values);
    console.log(" :", companyAd);
    if (companyAd?.id) {
      let request = {
        company_id: companyAd.id,
        data_config: JSON.stringify(values) // Convertir a JSON
      }
      console.log(request);
      dispatch(companyActions.updateCompanyDueBillConf(request));
    }
  };


  const unActivateDueConf = (id) => {
    Modal.confirm({
      title: `Desea activar/desactivar la configuración de mora?`,
      onOk: () => {
        let apply_due_conf_val = (companyAd?.applyDueCart == 1) ? 0 : 1;
        dispatch(companyActions.activateCompanyDueBillConf({ company_id: id, apply_due_conf: apply_due_conf_val}));
      },
      onCancel: () => {
      }
    })

  };

  return (
    <Card className="w-[800px] p-4 shadow-lg">
      <Col>
        <Button type='primary' onClick={() => unActivateDueConf(companyAd?.id)}>
          {labelConf}
        </Button>
      </Col>
      <br />
      <h2 className="text-lg font-bold mb-4"></h2>
      <Form
        form={form}
        onFinish={handleFinish}
        className="flex flex-col gap-4"
        initialValues={{
          date1: { diff_dia: 0, value: 0 },
          date2: { diff_dia: 0, value: 0 },
          date3: { diff_dia: 0, value: 0 },
        }}
      >
        {["date1", "date2", "date3"].map((date, index, arr) => (
          <div key={date} className="flex items-center gap-4 mb-2">
            <label className="w-64">Días de mora</label>
            <Form.Item
              name={[date, "diff_dia"]}
              rules={[
                { type: "number", transform: (value) => Number(value), message: "Debe ser un número" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value === undefined || value === null || isNaN(Number(value))) {
                      return Promise.reject(new Error("Ingrese un valor válido"));
                    }
                    if (index === 0) return Promise.resolve(); // No compara el primero 
                    const prevValue = Number(getFieldValue([arr[index - 1], "diff_dia"]) ?? 0);
                    console.log("Valor anterior:", prevValue);
                    if (Number(value) >= prevValue) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Debe ser mayor o igual al anterior"));
                  }
                }),
              ]}
              className="w-24"
            >
              <Input type="number" placeholder={`Dato ${index * 2 + 1}`} />
            </Form.Item>

            <label className="w-24">Porcentaje</label>
            <Form.Item
              name={[date, "value"]}
              rules={[
                { type: "number", transform: (value) => Number(value), min: 0, max: 100, message: "Debe ser entre 0 y 100" },
              ]}
              className="w-24"
            >
              <Input type="number" placeholder={`Dato ${index * 2 + 2} (0-100%)`} />
            </Form.Item>
          </div>
        ))}
        <Button type="primary" htmlType="submit" className="mt-4">
          Guadar
        </Button>
      </Form>
    </Card>
  );
};
