import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { countryActions } from './CountrySlice';

function* getCountryPrefix() { 
  try {
    console.log("dsdsdsdsdsdsdsdsdsdsds");
    //const bank = yield ApiService.get('bank', { country_id: payload })
    const prefix = yield ApiService.request('/country/prefix');
    console.log(prefix);
    yield put(countryActions.setPrefix(prefix)) 
  } catch (err) {
    yield put(countryActions.onError(err.toString()))
  }
}
 

function* ActionWatcher() {
  yield takeLatest(countryActions.getCountryPrefix, getCountryPrefix) 
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
