import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  status_load: null,
  errormsg: null,
  loading: true,
  countries: [],
  countriesPrefix: []
}

const countrySlice = createSlice({
  name: "country",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status_load = payload;
    }, 
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setPrefix(state, { payload }) {
      console.log("qqwwqwwqwq");
      state.countriesPrefix = payload;
    },
    getCountryPrefix(){}
  }
});

const countryActions = countrySlice.actions
const countryReducer = countrySlice.reducer

export { countryActions, countryReducer };