import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: null,
  errormsg: null,
  loading: true,
};

const twilioSlice = createSlice({
  name: "twilio",
  initialState: initialState,
  reducers: { 
    sendMessage({ payload }) { },
    checkMessageWhatapp(){},
    resendMessageWhatapp(){},
    onError(state, { payload }) {
        state.status = 'error';
        state.errormsg = payload;
      },
  },
});

const twilioActions = twilioSlice.actions;
const twilioReducer = twilioSlice.reducer;

export { twilioActions, twilioReducer };
