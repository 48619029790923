import { put, all, takeLatest, select } from 'redux-saga/effects'; 
import { twilioActions } from './TwilioSlice';
import ApiService from '../../common/api/Api'

function* sendMessage({payload}) { 
  console.log("🚀 ~ file: TwilioSaga.js:7 ~ function*sendMessage ~ data:",payload)
  try { 
    yield ApiService.requestPost(`/twilio/message`, payload)  
  } catch (err) {
    yield put(twilioActions.onError(err.toString()))
  }
}

function*  checkMessageWhatapp({payload}) {  
  try { 
    yield ApiService.request(`/twilio/message-status/${payload.messageId}` )  
  } catch (err) {
    yield put(twilioActions.onError(err.toString()))
  }
}

function*  resendMessageWhatapp({payload}) {  
  try { 
    yield ApiService.requestPost(`/user/resend_message/${payload.messageId}` )  
  } catch (err) {
    yield put(twilioActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(twilioActions.sendMessage, sendMessage)
  yield takeLatest(twilioActions.checkMessageWhatapp, checkMessageWhatapp)
  yield takeLatest(twilioActions.resendMessageWhatapp, resendMessageWhatapp)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
