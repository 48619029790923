import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { discountsActions } from "services/Discounts/DiscountsSlice";
import { shoppingActions } from 'services/Shopping/ShoppingSlice'
export const CartDiscountDispersionModal = ({ visible, discount, contractId, cartId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedEntityApplied, setSelectedEntityApplied] = useState(null);
  const [isFeeAppliedTypeZero, setIsFeeAppliedTypeZero] = useState(false);

  const singleOptions = [
    { value: 0, label: "Siempre" },
  ]
  const monthOptions = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
  ];

  const entity_applied = [
    { value: 1, label: "Yampi" },
    { value: 2, label: "Inmobiliaria" },
    { value: 3, label: "Propietario" },
    { value: 4, label: "administracion" },
    { value: 5, label: "Aseguradora" },
    { value: 7, label: "Inquilino" }
  ];
  const entity_applied_end = [
    { value: 0, label: "No aplica" },
    { value: 1, label: "Yampi" },
    { value: 2, label: "Inmobiliaria" },
    { value: 3, label: "Propietario" },
    { value: 4, label: "administracion" },
    { value: 5, label: "Aseguradora" }
  ];

  const type_value_fee = [
    { value: 1, label: "Porcentual" },
    { value: 0, label: "Nominal" },
  ];

  const type_fee_applied = [
    { value: 0, label: "no aplica" },
    { value: 1, label: "Yampi" },
    { value: 2, label: "Inmobiliaria" },
    { value: 3, label: "Aseguradora" },

  ];

  useEffect(() => {
      if (form.getFieldValue("fee_applied_type") === 0) {
        form.setFieldsValue({
          type_value_fee: 0,
          value_fee_applied: 0,
        });
        setIsFeeAppliedTypeZero(true);
      } else {
        setIsFeeAppliedTypeZero(false);
      }
    }, [form.getFieldValue("fee_applied_type")]);

  useEffect(() => {
    console.log(cartId) 
    setFilteredOptions(monthOptions) 
  }, [discount, form]);

  const hideModal = () => {
    dispatch(discountsActions.setState({ key: "discountDisModalCart", value: false }));
    dispatch(discountsActions.setState({ key: "discountDisSelected", value: null }));
  };

  const handleFinish = (values) => {
    values['contract_id'] = parseInt(contractId);
    dispatch(shoppingActions.discount({ cart_id: cartId, ...values }));
  };

  const changePeriodAppli = (value) => { 
  };

  const handleMonthChange = (value) => {
    // Actualizar el valor del formulario directamente
    form.setFieldsValue({
      period_applied: value,
    });
  };
  return (
    <Modal
      title={`Detalle del descuento a la dispersión`}
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      width="50%"
      className="ticket-modal"
    >
      <Form layout="vertical" name="createDiscount" form={form} onFinish={handleFinish}
      initialValues={{
        recurrent: false, // Valor fijo
        is_active: true,  // Valor fijo
      }}
       
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="concept"
              label="Descripción de la novedad "
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="value"
              label="Valor"
              rules={[
                {
                  required: true,
                  message: "Ingrese el valor de la novedad",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                size="large"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="recurrent"
              label="Frecuencia"
              rules={[
                {
                  required: true,
                  message: "Seleccione la frecuencia",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                size="large"
                onChange={changePeriodAppli}
                options={[
                  { value: true, label: "Periódico" },
                  { value: false, label: "Única vez" },
                ]}
                disabled
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type"
              label="Tipo"
              rules={[
                {
                  required: true,
                  message: "Seleccione el tipo",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                size="large"
                options={[
                  { value: false, label: "Descuento" },
                  { value: true, label: "Adición" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="is_active"
              label="Estado"
              rules={[
                {
                  required: true,
                  message: "Seleccione el estado",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                options={[
                  { value: false, label: "Inactivo" },
                  { value: true, label: "Activo" },
                ]}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="period_applied"
              label="periodo de aplicacion"
              rules={[{ required: true, message: "Seleccione el mes" }]}
            >
              <Select
                size="large"
                value={selectedMonth}
                onChange={handleMonthChange}
                options={filteredOptions}
                disabled={!filteredOptions.length} // Deshabilitar si no hay opciones
                placeholder="Selecciona un mes"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="entity_applied"
              label="a quien se aplica la novedad"
              rules={[{ required: true, message: "Seleccione una opcion" }]}
            >
              <Select
                size="large"
                options={entity_applied}
                placeholder="Selecciona una opcion"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="entity_applied_end"
              label="a quien se destina la novedad"
              rules={[{ required: true, message: "Seleccione una opcion" }]}
            >
              <Select
                size="large"
                options={entity_applied_end}
                placeholder="Selecciona una opcion"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fee_applied_type"
              label="tipo de tarifa aplicado"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                options={type_fee_applied}
                placeholder="Selecciona un tipo"
                onChange={(value) => {
                  if (value === 0) {
                    form.setFieldsValue({
                      type_value_fee: 0,
                      value_fee_applied: 0,
                    });
                    setIsFeeAppliedTypeZero(true);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type_value_fee"
              label="tipo de valor de la tarifa"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                options={type_value_fee}
                placeholder="Selecciona el tipo"
                onChange={(value) => {
                  form.setFieldsValue({ fee_applied_type: value });
                  setIsFeeAppliedTypeZero(value === 0);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="value_fee_applied"
              label="Valor de la tarifa"
              rules={[
                {
                  required: true,
                  message: "Ingrese el valor de la novedad",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const mainValue = getFieldValue("value");
                    if (value !== undefined && mainValue !== undefined && value > mainValue) {
                      return Promise.reject(new Error("El valor aplicado no puede ser mayor que el valor principal"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                size="large"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {discount ? "Editar" : "Agregar"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
