import React from 'react';
import { Tabs } from 'antd';

import { Detail, Payments, Files, PropertyPhotos, Features, Amenities, Messages } from '../../Sections';
import { Dispersion } from 'scenes/Private/Dispersion/Dispersion';
import { Configuration } from '../../Sections/Configuration/Configuration';
import { ConfigurationBill } from '../../Sections/ConfigurationBill/ConfigurationBill';
import { useSelector } from 'react-redux';
import { IncrementCart } from '../../Sections/IncrementCart/IncrementCart';

export const CompanyDataCard = () => {

    const { user } = useSelector((state) => state.auth);
    const rolesAdmin = ["sysadmin", "admin"];
    const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
   

    return (
        <div style={{ background: '#ffff', borderRadius: '12px', padding: 20, boxShadow: '0 20px 27px rgb(0 0 0/5%)' }}>
            <Tabs defaultActiveKey="1" tabBarStyle={{ fontSize: '22px' }} size='small'>
                <Tabs.TabPane tab="General" key="12" >
                    <Configuration />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Mensajes" key="11" >
                    <Messages />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Incrementos por mora" key="14" >
                    <IncrementCart />
                </Tabs.TabPane>
                {isAdmin ?
                (<Tabs.TabPane tab="Configuracion" key="13" >
                    <ConfigurationBill />
                </Tabs.TabPane>) : ("" ) }
            </Tabs>
        </div>
    )
};
